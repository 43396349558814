import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "antd"
import AdminLayout from "../../layouts/admin"
import cloudServer from "../../images/cloud-server.svg"
import { StationContext } from "../../contexts/stationContext"

const Location = ({ location }) => {
  if (!location) {
    return '';
  }
  return (
    <Row align="bottom">
      <Col>
        <img width="180px" src={cloudServer} alt="cloud-server" />
        <h1>{location.name}</h1>
      </Col>
      <Col>
        <table width="100">
          <tbody>
            {location.servers.sort((a,b) => parseInt(a.data.bitrate) - parseInt(b.data.bitrate)).map((server, index) => (
              <tr key={`row${index}`}>
                <td>{server.data.bitrate} kbps</td>
                <td>:</td>
                <td className="digital">{server.data.currentlisteners}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  )
}

const StatsPage = ({ location }) => {
  const {
    onlineStation,
    onlineStationError,
    onlineStationLoading,
    getOnlineStation,
  } = useContext(StationContext)
  const [locations, setLocations] = useState(null)

  useEffect(() => {
    getOnlineStation(true)
  }, [])

  useEffect(() => {
    if (onlineStation) {
      const locations = [];
      onlineStation.servers.forEach(server => {
        const vars = server.url.split("?")[1].split("&")
        var params = {}
        for (var i = 0; i < vars.length; i++) {
          var pair = vars[i].split("=")
          params[pair[0]] = decodeURIComponent(pair[1])
        }
        let loc = locations.findIndex(loc => loc.name === params.location)
        if (loc < 0) {
          loc = locations.push({ name: params.location, servers: [] }) - 1;
        }
        locations[loc].servers.push({ url: server.url, data: server.data });
      })
      setLocations(locations)
    }
  }, [onlineStation])

  return (
    <AdminLayout
      path={location.pathname}
      breadcrumbs={[{ link: "/admin/stats", title: "statistieken" }]}
    >
      <h1>Luister statistieken Hitstream.fm</h1>
      <span className="stats">
        <table width="80%">
          <tbody>
            <tr>
              <td rowSpan="2">
                {locations && (
                  <Location
                    location={locations.find(loc => loc.name === "Hoofd")}
                  />
                )}
              </td>
              <td>
                {locations && (
                  <Location
                    location={locations.find(loc => loc.name === "Zwartemeer")}
                  />
                )}
              </td>
            </tr>
            <tr>
              <td>
                {locations && (
                  <Location
                    location={locations.find(loc => loc.name === "Amsterdam")}
                  />
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </span>
    </AdminLayout>
  )
}

export default StatsPage
